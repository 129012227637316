import { HttpStatusCode } from 'axios';
import { ApiClient, Apps } from './types';

export type AppCreateResponse = {
  status: HttpStatusCode;
};

export type AppUpdateRequest = Partial<Apps> & Pick<Apps, 'id'>;

export const updateApp = (
  { POST }: ApiClient,
  appUpdateRequestData: AppUpdateRequest
) =>
  POST<AppUpdateRequest, AppCreateResponse>(
    `/api/user/updateApp`,
    appUpdateRequestData
  ).then(({ status }) => status);
