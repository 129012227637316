import React, { createContext } from 'react';
import { useThemeContextBehavior } from './useThemeContextBehavior';

export type ThemeContextState = ReturnType<typeof useThemeContextBehavior>;

export const ThemeContext = createContext<ThemeContextState | undefined>(
  undefined
);

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeContextProvider: React.FC<ThemeProviderProps> = ({
  children,
}) => {
  return (
    <ThemeContext.Provider value={useThemeContextBehavior()}>
      {children}
    </ThemeContext.Provider>
  );
};
