import React from "react";
import { TableTabs } from "../../templates/TableTabs/TableTabs";

import { Dashboard } from "../../templates/Dashboard/Dashboard";
// import { useAdminPageBehavior } from "./useAdminPageBehavior";


// TODO refactor - reusable markup to ui components
export const AdminPage: React.FC = () => {

  // const {
  //   addToolPopUpVisible,
  //   deletePopUpVisible,
  //   editPopUpVisible,
  //   onConfirmedDeleteTool,
  //   onCreateTool,
  //   onDeleteTool,
  //   onEditTool,
  //   onOpenEditTool,
  //   toolToEdit,
  //   hideAddToolPopUp,
  //   showAddToolPopUp,
  //   hideEditToolPopUp,
  //   hideDeletePopUp,
  //   toolInfo,
  // } = useAdminPageBehavior();

  return (
      <div className="w-full pt-12 text-sm pb-12">
        <div className="container mx-auto">
        <Dashboard></Dashboard>
        <TableTabs/>
        {/* <Modal visible={addCasesVisible} onClose={handleCloseModal} /> */}
        </div >
      </div>
  );
};
