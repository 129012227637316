import { useEffect, useMemo, useState } from 'react';
import { useGetUser } from '@app/domain/platform/context/useGetUser';
import { UserResponse } from '../../actions/getUser';

export const useUserContextBehavior = () => {
  const [userData, setUserData] = useState<
    UserResponse & { isInitialized: boolean }
  >({
    isUser: false,
    isDev: false,
    isAdmin: false,
    name: 'Login',
    id: 0,
    email: '',
    isInitialized: false,
  });

  const getUser = useGetUser();

  useEffect(() => {
    getUser().then((data) => setUserData({ ...data, isInitialized: true }));
  }, [getUser]);

  return useMemo(() => ({ userData }), [userData]);
};
