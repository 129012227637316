import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type TagVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends PropsWithChildren {
  className?: string;
  variant: TagVariants;
  component?: TagVariants;
}

export function Heading({
  className,
  children,
  variant,
  component: Component = variant,
}: HeadingProps) {
  return (
    <Component
      className={classNames(className, {
        'font-bandeins text-64 pb-8': variant === 'h1',
        'font-bandeins text-32 pb-7 lg:text-36': variant === 'h2',
        'font-bandeins-ha text-24 pb-6': variant === 'h3',
        'font-sen-bold text-24 pb-6': variant === 'h4',
        'font-bandeins-ha text-18 pb-5 lg:text-16': variant === 'h5',
        'font-sen-bold text-16 pb-4': variant === 'h6',
      })}
    >
      {children}
    </Component>
  );
}
