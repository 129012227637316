import classNames from 'classnames';
import { useMemo } from 'react';

export type ButtonSize = 'small' | 'normal';
export type ButtonVariant = 'primary' | 'neutral' | 'subtle' | 'danger';
export type ButtonContext = 'dark' | 'bright';

export interface UseButtonStyleProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  context?: ButtonContext;
  disabled?: boolean;
}

export function useButtonStyle({
  size = 'normal',
  variant,
  context = 'bright',
  disabled,
}: UseButtonStyleProps) {
  return useMemo(() => {
    const variantStyle = classNames({
      'bg-black text-white border-black':
        variant === 'primary' && context === 'bright',

      'hover:bg-white hover:text-black':
        ((variant === 'primary' && context === 'bright') ||
          ((variant === 'neutral' || variant === 'subtle') &&
            context === 'dark')) &&
        !disabled,

      'bg-white text-black border-black':
        variant === 'neutral' && context === 'bright',

      'hover:bg-black hover:text-white':
        (((variant === 'neutral' || variant == 'subtle') &&
          context === 'bright') ||
          (variant === 'primary' && context === 'dark')) &&
        !disabled,

      'hover:bg-red-700': variant === 'danger' && !disabled,

      'bg-red-600 text-white border-red-800':
        variant === 'danger' && context === 'bright',

      'bg-white text-black border-transparent':
        variant === 'subtle' && context === 'bright',

      'bg-white text-black border-white':
        variant === 'primary' && context === 'dark',

      'bg-black text-white border-white':
        variant === 'neutral' && context === 'dark',

      'bg-black text-white border-transparent':
        variant === 'subtle' && context === 'dark',

      'opacity-50 cursor-not-allowed': disabled,
    });

    return (
      variantStyle &&
      classNames(
        variantStyle,
        'inline-flex items-center justify-center rounded-full border-2 font-sen-bold text-s',
        {
          'h-12 px-8': size === 'normal',
          'h-10 w-24': size === 'small',
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, variant]);
}
