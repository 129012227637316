import Button from '@ui/components/Button/Button.tsx';
import { FC } from 'react';

interface DeleteAppData {
  isVisible: boolean;
  onClose: () => void;
  onDelete: () => void;
  bodyText: string;
  confirmButtonName: string;
  rejectButtonName: string;
}

const ConfirmModal: FC<DeleteAppData> = ({
  isVisible,
  onClose,
  onDelete,
  bodyText,
  confirmButtonName = 'Yes',
  rejectButtonName = 'No',
}) => {
  if (!isVisible) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
        <h1>{bodyText}</h1>
        <div className="flex justify-around py-4">
          <Button
            onClick={onDelete}
            label={confirmButtonName}
            size="normal"
            variant="danger"
            addClass="w-44"
          />
          <Button
            onClick={onClose}
            label={rejectButtonName}
            size="normal"
            variant="neutral"
            addClass="w-44"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
