"use client";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { truncate } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { UseCase } from "./UseCasesTable.type";






export const UseCasesTableColumns: ColumnDef<UseCase, unknown>[] = [
    {
        accessorKey: "id",
        header: "ID",
    },
    {
        accessorKey: "title",
        header: "Title",
    },
    {
        accessorKey: "image",
        header: "Image",
        cell: ({ row }) => {
            const img = row.original;
            return (
                <Avatar>
                    <AvatarImage src={img.image} />
                    <AvatarFallback>CN</AvatarFallback>
                </Avatar>
            )
        }
    },
    // {
    //     accessorKey: "short_description",
    //     header: "Description",
    // },
    {
        accessorKey: "long_description",
        header: "Description",
        cell: ({ row }) => {
            const descr = row.original.long_description;
            if (!descr) {
                return (
                    <div>No Description</div>
                )
            }
            return (
                <div>{truncate(descr, 40)}</div>
            )
        }
    },
    {
        accessorKey: "tags",
        header: "Tags",
    },
    {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
            const id = row.original.id
            const handleDeleteUseCase = (id: number) => {
                console.log("Delete useCase ", id);
            }
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem onClick={() => handleDeleteUseCase(id)}>
                            Delete Use Case
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    },
];