import { PropsWithChildren, useState } from 'react';
import { Heading, HeadingProps } from '@ui/components/Heading';

export interface AccordionProps extends PropsWithChildren {
  title: Partial<HeadingProps>;
}

export const Accordion = ({ title, children }: AccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <>
      <div className="block relative text-2xl leading-none py-5 mx-6 sm:mx-8 sm:text-3xl lg:my-2">
        <button
          onClick={() => setAccordionOpen(!accordionOpen)}
          className="flex justify-between gap-2 items-center w-full focus:outline-none text-left"
        >
          <Heading variant="h4" {...title} />
          <svg
            className={`w-6 h-6 transform transition-transform flex-shrink-0 ${
              accordionOpen ? 'rotate-180' : ''
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
            accordionOpen
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          }`}
        >
          <div className="overflow-hidden text-black text-lg font-sen font-medium">
            <div className="mt-4"> {children} </div>
          </div>
        </div>
      </div>
      <span className="block h-0.5 bg-black mx-auto 3xl:mx-10"></span>
    </>
  );
};
