import React, { useCallback, useEffect, useState } from "react";

import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { HttpStatusCode } from "axios";

import "./UserPage.css";
import { useUserContext } from "@app/domain/platform/context/UserContext";
import { UserInfo } from "@app/domain/platform/templates/UserInfo";
import { AppTable } from "@app/domain/platform/templates/UserAppTable";
import { UsageTable } from "@app/domain/platform/templates/UserUsageTable";
import { AppModelUsageTable } from "@app/domain/platform/templates/AppUsageTable";
import { AddAppPopUp } from "@app/domain/platform/templates/AddAppPopUp";
import { EditAppPopUp } from "@app/domain/platform/templates/EditAppPopUp";
import { useGetUserData } from "@app/domain/platform/context/useGetUserData.ts";
import { useCreateApp } from "@app/domain/platform/context/useCreateApp";
import { useDeleteApp } from "@app/domain/platform/context/useDeleteApp.ts";
import { useUpdateApp } from "@app/domain/platform/context/useUpdateApp.ts";
import ConfirmModal from "@ui/patterns/ConfirmModal";
import { AppUsage, ModelUsage } from "../../actions/getUserData";
import { Apps } from "../../actions/types";
import { AppUpdateRequest } from "../../actions/updateApp";
// import { UserTable } from "./UserTable";

interface EditAppData {
  name: string;
  project_number: string;
  description: string;
  email?: string;
  id: number;
  app_name?: string;
}

// TODO refactor - reusable markup to ui components
export const UserPage: React.FC = () => {
  const {
    userData: { isUser, isAdmin, name, id },
  } = useUserContext();

  const [modelUsage, setModelUsage] = useState<ModelUsage[]>([]);
  const [appUsage, setAppUsage] = useState<AppUsage[]>([]);
  const [appInfo, setAppInfo] = useState<Apps[]>([]);

  const [addPopUpVisible, setAddPopupVisible] = useState(false);
  const [editPopUpVisible, setEditPopUpVisible] = useState(false);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const [showCopyPopup, setShowCopyPopup] = useState(false);

  const onAddApp = () => {
    setAddPopupVisible(true);
    handleGetApps();
  };

  const [appToEdit, setAppToEdit] = useState<EditAppData>({
    name: "",
    description: "",
    id: 0,
    project_number: "",
  });
  const [appIdtoDelete, setAppIdToDelete] = useState(0);

  const getUserData = useGetUserData();
  const createApp = useCreateApp();
  const deleteApp = useDeleteApp();
  const updateApp = useUpdateApp();

  const handleGetApps = useCallback(() => {
    getUserData().then((data) => {
      setModelUsage(data.model_usage);
      setAppUsage(data.app_usage);
      setAppInfo(data.apps);
    });
  }, [getUserData]);

  const onCreateApp = (formData: Apps) => {
    if (id) {
      formData.id = id;
      createApp(formData).then((status) => {
        if (status == HttpStatusCode.Ok) {
          setAddPopupVisible(false);
          handleGetApps();
        }
      });
    }
  };

  const onOpenEditApp = (data: Apps) => {
    setAppToEdit({ ...data });
    setEditPopUpVisible(true);
  };

  const onEditApp = (formData: AppUpdateRequest) => {
    console.log("Editing app with data:", formData);

    updateApp(formData).then((status) => {
      if (status == HttpStatusCode.Ok) {
        setEditPopUpVisible(false);
        handleGetApps();
      }
    });
  };

  function onConfirmedDeleteApp() {
    if (appIdtoDelete) {
      const deletionData = { id: appIdtoDelete };
      deleteApp(deletionData).then((status) => {
        if (status == HttpStatusCode.Ok) {
          setDeletePopUpVisible(false);
          handleGetApps();
        }
      });
    }
  }

  function onDeleteApp(id: number) {
    setDeletePopUpVisible(true);
    setAppIdToDelete(id);
  }

  useEffect(() => {
    handleGetApps();
  }, [handleGetApps]);

  return (
    <div className="w-full 2xl:max-w-screen-2xl mx-auto pt-24">
      <div className="container mx-auto">
        {isUser ? (
          <>
            <h1>User Area</h1>
            {/* <UserTable></UserTable> */}
            <h3>
              Hello {name.split(" ")[0]}! We are happy to have you on board!
            </h3>
            <div className="user-section mb-12 flex w-full mt-8">
              <div className="w-1/4 pr-4">
                <UserInfo name={name} isAdmin={isAdmin} />
              </div>
              <div className="w-full pl-4 border-l flex flex-col">
                <div className="flex justify-between items-center mb-4">
                  <h1 className="">Apps</h1>
                  <div>
                    <span className="mr-2 font-sen-bold text-gray-600">
                      Add App
                    </span>
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={onAddApp}
                      className="ml-2"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </div>
                </div>
                <table className="min-w-full border rounded-md">
                  <thead>
                    <tr className="text-black uppercase text-sm leading-normal font-sen-bold border-b-2 border-gray-200">
                      <th className="py-2 px-2 md:px-4 text-start h-14">Id</th>
                      <th className="py-2 px-2 md:px-4 text-start h-14">Name</th>
                      <th className="py-2 px-2 md:px-4 text-start h-14">
                        Description
                      </th>
                      <th className="py-2 px-2 md:px-4 text-center">
                        Projectnumber
                      </th>
                      <th className="py-2 px-2 md:px-4 text-start">
                        Access Token
                      </th>
                      <th className="py-2 px-2 md:px-4 text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-black text-sm font-light">
                    <AppTable
                      appList={appInfo}
                      setCopyPopUp={setShowCopyPopup}
                      edit={onOpenEditApp}
                      delete={onDeleteApp}
                    />
                  </tbody>
                </table>
                <div className="flex w-full my-4">
                  <div className="w-full pr-4 ">
                    <h2 className=" mb-4">Model Usage</h2>
                    <UsageTable appList={modelUsage} />
                  </div>
                  <div className="w-full pl-4">
                    <h2 className=" mb-4">App Usage</h2>
                    <AppModelUsageTable appList={appUsage} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>Bitte einloggen.</div>
        )}

        {showCopyPopup && (
          <div
            className="fixed top-12 left-1/2 transform -translate-x-1/2 m-4 p-2 rounded bg-green-500 text-white animate-slideInFromTop font-sen-bold"
            style={{ animationDuration: "0.5s", zIndex: 1000 }}
          >
            Copied to clipboard
          </div>
        )}
        <AddAppPopUp
          isVisible={addPopUpVisible}
          onClose={() => setAddPopupVisible(false)}
          onAddApp={(formData) => {
            onCreateApp(formData);
          }}
        />
        <EditAppPopUp
          isVisible={editPopUpVisible}
          onClose={() => setEditPopUpVisible(false)}
          appToEdit={appToEdit}
          onAddApp={onEditApp}
        />
        <ConfirmModal
          isVisible={deletePopUpVisible}
          bodyText="Are you sure, that you want to delete the app?"
          onClose={() => setDeletePopUpVisible(false)}
          onDelete={onConfirmedDeleteApp}
          confirmButtonName={"Delete"}
          rejectButtonName={"Cancel"}
        />
      </div>
    </div>
  );
};
