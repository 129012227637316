import React from "react";
import { DataTable } from "@/ui/modules/DataTable/DataTable";
import { UseCasesTableProps } from "./UseCasesTable.type";
import { UseCasesTableColumns } from "./UseCasesTableColumns";

export const UseCasesTable: React.FC<UseCasesTableProps> = ({ data }) => {
    return (
        <div>
            <DataTable columns={UseCasesTableColumns} data={data} />
        </div>
    )
};