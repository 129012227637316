import './ComingSoon.css';

interface SpanItem {
  text: string;
  dark?: boolean;
}
interface ComingSoonItem {
  span: SpanItem[];
}
interface ComingSoonProps {
  coming_soon: ComingSoonItem[];
}

export const ComingSoon = ({ coming_soon }: ComingSoonProps) => {
  return (
    <div className="coming-soon">
      {coming_soon.map((item, index) => (
        <div
          key={index}
          className="xl:text-9xl md:text-7xl text-6xl font-bandein whitespace-nowrap"
        >
          {item.span.map((spanItem, spanIndex) => (
            <span
              key={spanIndex}
              className={`moving-span${index} ${spanItem.dark ? 'dark' : ''}`}
            >
              {spanItem.text}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};
