import { ApiClient } from '@app/domain/platform/actions/types';

export interface User {
  id: number;
  name: string;
  email: string;
  user_role: number | string;
  tokens_used_sum: number;
}

export enum UserRole {
  Admin = 1,
  User = 2,
  PowerUser = 3,
  Developer = 4,
}

export const userRoleMapping: { [key in UserRole]: string } = {
  [UserRole.Admin]: 'Admin',
  [UserRole.User]: 'User',
  [UserRole.PowerUser]: 'Power User',
  [UserRole.Developer]: 'Developer',
};

export const getAllUsersWithToken = ({ GET }: ApiClient) =>
  GET<User[]>('/api/admin/getAllUsersWithToken').then(({ data }) =>
    data.map((user: User) => ({
      id: user.id,
      email: user.email,
      name: user.name,
      user_role: userRoleMapping[user.user_role as UserRole],
      tokens_used_sum: user.tokens_used_sum,
    }))
  );
