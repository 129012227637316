import { Footer as FooterImpl } from '@ui/patterns/Footer/';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const Footer = () => (
  <FooterImpl
    heading={{ children: 'Your opinion matters!' }}
    link={{
      href:
        'https://teams.microsoft.com/l/team/19%3A5DRmzgZksYMoGiBpdUwGgYY' +
        'TlvOxe2H8dYFRU6w4f6M1%40thread.tacv2/conversations?groupId=34f7f336-3acd-4' +
        'c81-8cf0-4d831f2b9cc4&tenantId=338159a0-dd8f-45af-9672-faaa54bc6b24',
      children: (
        <>
          <MailOutlineIcon style={{ marginRight: '8px' }} />
          Contact Us
        </>
      ),
    }}
    text={{
      children:
        'Have any questions or feedback? Help us make the Exxeta ai platform' +
        '                    better!',
    }}
    copyrightText={{
      children: (
        <>
          This App Was Created By <br />
          Lindenberg, André | Michalik, Philipp | Sternagel, Jan | Schmid,
          Florian | Levendovský, Stanislav | Herrmann, Loris | Martin, Alexander
          | Vlasov, Alisa | Tuna, Benjamin
        </>
      ),
    }}
    version={{ children: '1.0.5' }}
  />
);
