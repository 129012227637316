import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@/ui/components/Button/Button';

interface EditAppData {
  id: number;
  project_number: string;
  name: string;
  description: string;
}

interface EditAppPopUpProps {
  isVisible: boolean;
  onClose: () => void;
  appToEdit: EditAppData;
  onAddApp: (userData: EditAppData) => void;
}

// TODO refactor - reusable markup to ui components
export const EditAppPopUp: React.FC<EditAppPopUpProps> = ({
  isVisible,
  onClose,
  onAddApp,
  appToEdit,
}) => {
  const [formData, setFormData] = useState({
    id: 0,
    project_number: '',
    description: '',
    name: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    setFormData({
      id: appToEdit.id,
      project_number: appToEdit.project_number ?? '',
      description: appToEdit.description ?? '',
      name: appToEdit.name,
    });
  }, [appToEdit]);

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault();
      onAddApp(formData);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // If using Axios for HTTP requests
        setErrorMessage(error?.response?.data?.detail);
      } else {
        setErrorMessage('An unexpected error occurred');
      }
      setShowErrorMessage(true);
    }
  };

  const handleClose = () => {
    setShowSuccessMessage(false);
    setErrorMessage(null);
    setShowErrorMessage(false);
    setFormData({
      id: 0,
      project_number: '',
      description: '',
      name: '',
    });
    onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      {showSuccessMessage ? (
        <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="py-8 font-sen-bold">User signed up</h1>
            <Button
              onClick={handleClose}
              label="Ok"
              size="normal"
              variant="primary"
              addClass="w-40"
            />
          </div>
        </div>
      ) : showErrorMessage ? (
        <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="py-8 font-sen-bold">Error: ${errorMessage}</h1>
            <Button
              onClick={handleClose}
              label="Ok"
              size="normal"
              variant="primary"
              addClass="w-40"
            />
          </div>
        </div>
      ) : (
        <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
          <h1 className=" font-bold text-xl py-4 font-bandeins">
            Edit '{appToEdit.name}'
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-black mb-2">Projectnumber:</label>
              <input
                type="text"
                name="project_number"
                value={formData.project_number}
                onChange={handleChange}
                className="p-2 w-full border rounded bg-gray-100 border-gray-300"
              />
            </div>

            <div className="mb-4">
              <label className="block text-black mb-2">Description:</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="p-2 w-full border rounded bg-gray-100 border-gray-300"
              />
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div className="flex justify-around py-4">
              <Button
                onClick={(e) => handleSubmit(e)}
                label="Edit"
                size="normal"
                variant="primary"
                addClass="w-44"
              />
              <Button
                onClick={onClose}
                label="Close"
                size="normal"
                variant="neutral"
                addClass="w-44"
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
