import React from "react";
import { UsersTableColumns } from "./UsersTableColumns";
import { DataTable } from "@/ui/modules/DataTable/DataTable";
import { UsersTableProps } from "./UsersTable.type";

export const UsersTable: React.FC<UsersTableProps> = ({ data }) => {
    return (
        <div>
            <DataTable columns={UsersTableColumns} data={data} />
        </div>
    )
};