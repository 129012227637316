export type appModelUsage = {
  name: string;
  total_tokens: number;
};

type appModelUsageTableProps = {
  appList: appModelUsage[];
};

// TODO refactor - move reusable markup to ui components
export const AppModelUsageTable = ({ appList }: appModelUsageTableProps) => {
  // Filter out "unknown" names before mapping to avoid null returns
  const filteredApps = appList.filter((app) => app.name !== 'unknown');

  return (
    <>
      <div className="flex-grow">
        <table className="min-w-full border rounded-md">
          <thead>
            <tr className="text-black uppercase text-sm leading-normal font-sen-bold border-b-2 border-gray-200">
              <th
                className="py-2 px-2 md:px-4 text-start"
                style={{ width: '60%' }}
              >
                App
              </th>
              <th
                className="py-2 px-2 md:px-4 text-start"
                style={{ width: '40%' }}
              >
                Usage Tokens
              </th>
            </tr>
          </thead>
          <tbody className="text-black text-sm font-light">
            {filteredApps.length > 0 ? (
              filteredApps.map((item, index) => (
                <tr
                  key={item.name + index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td
                    className="py-2 px-2 md:px-4 text-start"
                    style={{ width: '60%' }}
                  >
                    {item.name}
                  </td>
                  <td
                    className="py-2 px-2 md:px-4 text-start"
                    style={{ width: '40%' }}
                  >
                    {item.total_tokens
                      .toLocaleString('de-DE')
                      .replace(/,/g, '.')}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} className="text-center py-2">
                  No app usage to display.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
