import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { createContext, PropsWithChildren } from 'react';
import {
  useAuthenticationContextBehavior,
  UseAuthenticationContextBehaviorProps,
} from './useAuthenticationContextBehavior';
import { MsalInstanceProps, useMsalInstance } from './useMsalInstance';

export type AuthenticationContextState = ReturnType<
  typeof useAuthenticationContextBehavior
>;

export const AuthenticationContext = createContext<
  AuthenticationContextState | undefined
>(undefined);

type AuthenticationContextProviderImplProps = PropsWithChildren &
  UseAuthenticationContextBehaviorProps;

export const AuthenticationContextProviderImpl = ({
  children,
  appScopes,
}: AuthenticationContextProviderImplProps) => {
  const value = useAuthenticationContextBehavior({
    appScopes,
  });

  return (
    <AuthenticationContext.Provider value={value}>
      {value.inProgress === 'none' && children}
    </AuthenticationContext.Provider>
  );
};

export type AuthenticationContextProviderProps = PropsWithChildren &
  MsalInstanceProps &
  AuthenticationContextProviderImplProps;

export const AuthenticationContextProvider = ({
  children,
  authority,
  clientId,
  redirectUri,
  appScopes,
}: AuthenticationContextProviderProps) => {
  const msalInstance = useMsalInstance({
    authority,
    clientId,
    redirectUri,
  });

  if (msalInstance)
    return (
      <MsalProvider instance={msalInstance}>
        <AuthenticationContextProviderImpl appScopes={appScopes}>
          <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        </AuthenticationContextProviderImpl>
      </MsalProvider>
    );
};
