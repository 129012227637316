import { ApiClient } from '@app/domain/platform/actions/types';

export type RoleUpdateResponse = {
  message: string;
};

export type RoleUpdateRequest = {
  id: number;
  role_id: number;
};

export const updateRole = (
  { POST }: ApiClient,
  toolPutRequestData: RoleUpdateRequest
) =>
  POST<RoleUpdateRequest, RoleUpdateResponse>(
    `/api/admin/setRole`,
    toolPutRequestData
  ).then(({ status }) => status);
