import { AdminPage } from "@app/domain/admin/pages/AdminPage";
import { ProtectedPage } from "@app/domain/platform/templates/ProtectedPage";
import { AdminProvider } from "../context/AdminContextProvider";
export const adminRoutes = [
  {
    path: "/admin",
    element: (
      <ProtectedPage requireIsDev requireIsAdmin>
        <AdminProvider>
          <AdminPage />
        </AdminProvider>
      </ProtectedPage>
    ),
  },
];
