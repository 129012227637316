import { ApiClient } from '@app/domain/platform/actions/types';

//TODO: change types based on comments below after JAVA backend is ready
export type CostPerMonth = {
  month: number;
  totalCost: number;
  totalTokens: number;
  inputCost?: number; 
  outputCost?: number;
};

export const getCostYTD = ({ GET }: ApiClient) =>
  GET<CostPerMonth[]>('/api/admin/getCostYTD').then(({ data }) => data);
