import {
  ButtonContext,
  ButtonSize,
  ButtonVariant,
  useButtonStyle,
} from '@ui/tokens/useButtonStyle';
import { LinkVariant, useLinkStyle } from '@ui/tokens/useLinkStyle';
import classNames from 'classnames';
import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

export type ButtonProps = PropsWithChildren & {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * @deprecated use className
   */
  label?: ReactNode;
  className?: string;
  /**
   * @deprecated use className
   */
  addClass?: string;
} & (
    | {
        variant?: ButtonVariant;
        size?: ButtonSize;
        context?: ButtonContext;
        disabled?: boolean;
        linkVariant?: undefined;
      }
    | {
        variant?: undefined;
        size?: undefined;
        context?: undefined;
        disabled?: undefined;
        linkVariant?: LinkVariant;
      }
  );

export const Button: FC<ButtonProps> = ({
  onClick,
  label,
  size,
  variant,
  context,
  linkVariant,
  addClass,
  className = addClass,
  disabled,
  children,
}) => {
  const buttonClasses = useButtonStyle({
    size: size,
    variant: linkVariant ? undefined : variant || 'primary',
    context,
    disabled,
  });

  const linkClasses = useLinkStyle({
    variant: linkVariant,
  });

  return (
    <button
      onClick={disabled ? undefined : onClick}
      className={classNames(className, buttonClasses, linkClasses)}
      disabled={disabled}
    >
      {label || children}
    </button>
  );
};

export default Button;
