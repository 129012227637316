import { useCallback, useEffect, useState } from "react";
import { getTools } from "../actions/getTools";
import { useApiClient } from "@app/domain/platform/context/apiClient";
import { UseCase } from "./AdminContextProvider/AdminContext.types";

export const useFetchUseCases = () => {
  const apiClient = useApiClient();
  const [useCases, setUseCases] = useState<UseCase[]>([]);

  const fetchUseCases = useCallback(() => {
    getTools(apiClient)
      .then((data: UseCase[]) => {
        setUseCases(data);
      })
      .catch((error: Error) => console.error("Error fetching tools:", error));
  }, [apiClient]);

  useEffect(() => {
    fetchUseCases();
  }, [fetchUseCases]);

  return { useCases, fetchUseCases };
};
