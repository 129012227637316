import { useAdminContext } from "./useAdminContext";
import { useUpdateRole } from "../useUpdateRole";
import { useToast } from "@/components/ui/use-toast";
import { userRoleMappingID } from "@/lib/utils";
// import { useCreateTool } from "../useCreateTool";
// import { useDeleteTool } from "../useDeleteTool";
// import { useUpdateTool } from "../useUpdateTools";

import { useDeleteApp } from "../../../platform/context/useDeleteApp";


const useAdminContextBehaviour = () => {
    const { refreshUsers, users, refreshApps } = useAdminContext();
    const { toast } = useToast();
     
    const delApp = useDeleteApp();
    const deleteApp = async (app_id: number) => {
        try {
            const status = await delApp({id: app_id});
            if (status === 200) {
                refreshApps();
                toast({
                    title: `App with ${app_id} deleted successfully.`,
                });
                
            } else {
                toast({
                    title: `Failed to delete app`,
                    description: "Please try again later.",
                });
            }
        } catch (error) {
            console.error("Error deleting app", error);
        }
    }

    const updateRole = useUpdateRole();

    const updateUserRole = async (userId: number, newRoleId: number) => {
        try {
            const request = {
                id: userId,
                role_id: newRoleId,
            };

            const status = await updateRole(request);
            if (status === 200) {
                refreshUsers();
                const roleName = userRoleMappingID[newRoleId];
                const user = users?.find((user) => user.id === userId);
                const name = user?.name || `with ID ${userId}`;

                toast({
                    title: `Role updated successfully.`,
                    description: `User ${name} is now a ${roleName}`,
                });
            } else {
                toast({
                    title: `Failed to update role`,
                    description: "Please try again later.",
                });
            }
        } catch (error) {
            console.error("Error updating role", error);
        }
    };

    return {
        updateUserRole,
        deleteApp
    };
};

export default useAdminContextBehaviour;