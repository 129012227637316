import { useCallback, useMemo } from 'react';
// Using makeApiCall here is fine because this is the central abstraction for api calls
// TODO refactor makeApiCall to be an axios interceptor
import { makeApiCall } from './api';
import { useAuthenticationContext } from '../AuthenticationContext';
import { ApiClient } from '../../actions/types';

export function useApiClient(): ApiClient {
  const { getAuthorization, getAuthorizationWithInteraction } =
    useAuthenticationContext();

  const GET = useCallback(
    <R>(path: string) =>
      makeApiCall<undefined, R>(
        getAuthorization,
        getAuthorizationWithInteraction,
        path,
        'GET'
      ),
    [getAuthorization, getAuthorizationWithInteraction]
  );

  const PUT = useCallback(
    <T, R>(path: string, data: T) =>
      makeApiCall<T, R>(
        getAuthorization,
        getAuthorizationWithInteraction,
        path,
        'PUT',
        data
      ),
    [getAuthorization, getAuthorizationWithInteraction]
  );

  const POST = useCallback(
    <T, R>(path: string, data: T) =>
      makeApiCall<T, R>(
        getAuthorization,
        getAuthorizationWithInteraction,
        path,
        'POST',
        data
      ),
    [getAuthorization, getAuthorizationWithInteraction]
  );

  const DELETE = useCallback(
    <R>(path: string) =>
      makeApiCall<undefined, R>(
        getAuthorization,
        getAuthorizationWithInteraction,
        path,
        'DELETE'
      ),
    [getAuthorization, getAuthorizationWithInteraction]
  );

  return useMemo(
    () => ({
      GET,
      PUT,
      POST,
      DELETE,
    }),
    [GET, PUT, POST, DELETE]
  );
}
