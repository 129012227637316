import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { Dispatch, SetStateAction } from "react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function addDotsToNumber(num: number) {
  if (num === null) return 0;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const copyToClipboard = (
  text: string,
  setCopyPopUp: Dispatch<SetStateAction<boolean>>
) => {
  navigator.clipboard
      .writeText(text)
      .then(() => {
          setCopyPopUp(true);
          setTimeout(() => setCopyPopUp(false), 1500);
      })
      .catch((err) => {
          console.error("Could not copy text: ", err);
      });
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};


export const toMillion = (value: number) => {
  return (value / 1000000).toFixed(1);
}


export const monthNames: { [key: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};

export const userRoleMappingID: { [key: number]: string } = {
  1: "Admin",
  2: "User",
  3: "PowerUser",
  4: "Developer",
};
