'use client';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { UserRole } from '../../actions/getAllUsersWithToken';
import { addDotsToNumber } from '@/lib/utils';
import { type UserType } from './UsersTable.type';
import useAdminContextBehaviour from '../../context/AdminContextProvider/useAdminContextBehavior';

const handleDeleteUser = (user_id: number) => {
    console.log('Delete user with id: ', user_id);
};

export const UsersTableColumns: ColumnDef<UserType, unknown>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
    },
    {
        accessorKey: 'name',
        header: 'Name',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => {
            return (
                <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Email
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
    },
    {
        accessorKey: 'user_role',
        header: 'Role',
        cell: ({ row }) => {
            const user = row.original;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { updateUserRole } = useAdminContextBehaviour();
            const handleRoleChange = async (newRole: number) => {
                await updateUserRole(user.id, newRole);
            };

            return (
                <div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-full text-left">
                                {user.user_role}
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={() => handleRoleChange(UserRole.Admin)}>Admin</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => handleRoleChange(UserRole.User)}>User</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => handleRoleChange(UserRole.PowerUser)}>
                                PowerUser
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => handleRoleChange(UserRole.Developer)}>
                                Developer
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            );
        },
    },
    {
        accessorKey: 'tokens_used_sum',
        header: ({ column }) => {
            return (
                <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Tokens Used
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            const user = row.original;
            const tokensUsed = user.tokens_used_sum;
            return addDotsToNumber(tokensUsed);
        },
    },
    {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
            const user_id = row.original.id;
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem onClick={() => handleDeleteUser(user_id)}>Delete User</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    },
];
