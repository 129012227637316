import { ApiClient } from '@app/domain/platform/actions/types';

export type TotalUsersResponse = {
  known_users: number;
  active_users: number;
};

export type TotalUsersMap = {
  knownUsers: number;
  activeUsers: number;
};

export const getTotalUsers = ({ GET }: ApiClient) =>
  GET<TotalUsersResponse>('/api/admin/getTotalUsers').then(
    ({ data }): TotalUsersMap => ({
      knownUsers: data.known_users,
      activeUsers: data.active_users,
    })
  );
