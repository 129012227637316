import { HttpStatusCode } from 'axios';
import { ApiClient } from './types';

export type AppDeleteResponse = {
  status: HttpStatusCode;
};

export type AppDeleteRequest = {
  id: number;
};

export const deleteApp = (
  { POST }: ApiClient,
  appDeleteRequest: AppDeleteRequest
) => POST(`/api/user/deleteApp`, appDeleteRequest).then(({ status }) => status);
