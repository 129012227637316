import { PublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';

export interface MsalInstanceProps {
  clientId: string;
  authority: string;
  redirectUri: string;
}

export function useMsalInstance({
  authority,
  clientId,
  redirectUri,
}: MsalInstanceProps) {
  const [msalInstance, setMsalInstance] = useState<
    PublicClientApplication | undefined
  >(undefined);

  useEffect(() => {
    if (!msalInstance) {
      setMsalInstance(
        new PublicClientApplication({
          auth: {
            authority,
            clientId,
            redirectUri,
          },
        })
      );
    }
  }, [authority, clientId, msalInstance, redirectUri]);

  return msalInstance;
}
