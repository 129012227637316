import { useCallback, useEffect, useState } from 'react';
import { CostPerMonth, getCostYTD } from '../actions/getCostYTD';
import { useApiClient } from '@app/domain/platform/context/apiClient';

export const useCostYTD = () => {
  const apiClient = useApiClient();
  const [costYTDData, setCostYTDData] = useState<CostPerMonth[] | []>([]);

  const handleGetCostYDT = useCallback(() => {
    getCostYTD(apiClient).then(setCostYTDData);
  }, [apiClient]);

  useEffect(() => {
    handleGetCostYDT();
  }, [handleGetCostYDT]);

  return { costYTDData, handleGetCostYDT };
};
