import { useEffect, useState } from 'react';
import { getTotalApps, TotalAppsMap } from '../actions/getTotalApps';
import { useApiClient } from '@app/domain/platform/context/apiClient';

const useTotalApps = () => {
  const apiClient = useApiClient();
  const [totalApps, setTotalApps] = useState<TotalAppsMap>({
    totalApps: 0,
  });

  useEffect(() => {
    getTotalApps(apiClient).then(setTotalApps);
  }, [apiClient]);

  return totalApps;
};

export default useTotalApps;
