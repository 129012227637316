import { Heading, HeadingProps } from '@ui/components/Heading';
import { Link, LinkProps } from '@ui/components/Link';
import { PictureProps } from '@ui/components/Picture';

interface HomeHeroProps {
  heading: Partial<HeadingProps>;
  text: Partial<HeadingProps>;
  backgroundImage: Partial<PictureProps>;
  heroImage: PictureProps;
  docsLink: LinkProps;
  casesLink: LinkProps;
}

export const HomeHero = ({
  heading,
  text,
  heroImage,
  docsLink,
  backgroundImage,
  casesLink,
}: HomeHeroProps) => {
  // TODO introduce BackgroundImage Atom
  return (
    <div
      className="w-full bg-cover bg-center bg-no-repeat h-[980px]"
      style={{ backgroundImage: `url(${backgroundImage.src})`, opacity: 0.92 }}
    >
      <div className="py-16 lg:py-32 3xl:my-44 2xl:max-w-screen-2xl 2xl:mx-auto px-6 sm:px-0 2xl:px-10 container mx-auto">
        <div className="flex flex-col md:flex-row items-center gap-12 justify-between mt-24">
          <div>
            <Heading
              variant="h1"
              className="font-bandeins-ha text-6xl md:text-8xl text-black"
              {...heading}
            />
            <Heading variant="h4" {...text} />
            <div className="flex">
              <div>
                <Link
                  {...docsLink}
                  variant={undefined}
                  buttonContext="bright"
                  buttonVariant="primary"
                  className="w-32 hover:!bg-transparent"
                />
                <Link
                  {...casesLink}
                  variant={undefined}
                  buttonContext="bright"
                  buttonVariant="neutral"
                  className="w-32 ml-2 !bg-transparent hover:!bg-black"
                />
              </div>
            </div>
          </div>
          <img {...heroImage} />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
