import { Heading } from '@ui/components/Heading';
import { Picture } from '@ui/components/Picture';
import { SectionFrame } from '@ui/components/SectionFrame';
import { Text } from '@ui/components/Text';
import classNames from 'classnames';

interface InformationTextProps {
  text: string;
  answer: string;
  image: string;
  isDarkBackground: boolean;
}

export const InformationText = ({
  text,
  answer,
  image,
  isDarkBackground,
}: InformationTextProps) => {
  return (
    <SectionFrame isDarkBackground={isDarkBackground}>
      <div className="py-16 px-6 sm:px-0 gap-8 md:gap-x-8 grid md:grid-cols-2 md:items-center lg:gap-x-12  xl:min-h-[40rem] 2xl:max-w-screen-2xl 2xl:mx-auto image-invert container mx-auto">
        <Picture
          className={`${
            isDarkBackground ? 'md:order-2' : ''
          } w-full h-full object-contain`}
          src={image}
          alt="related"
        />
        <article
          className={classNames('text-left sm:max-w-2xl sm:mx-auto order-1', {
            'text-white': isDarkBackground,
          })}
        >
          <Heading
            variant="h3"
            className="font-bandein font-bold leading-none mb-4 text-4xl xl:text-5xl"
          >
            {text}
          </Heading>
          <Text className="font-sen text-lg">{answer}</Text>
        </article>
      </div>
    </SectionFrame>
  );
};
