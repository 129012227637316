import React, { useEffect, useState } from 'react';
import Button from '@/ui/components/Button/Button';

interface AddAppPopUpProps {
  isVisible: boolean;
  onClose: () => void;
  onAddApp: (userData: any) => void;
}

// TODO refactor - reusable markup to ui components
export const AddAppPopUp: React.FC<AddAppPopUpProps> = ({
  isVisible,
  onClose,
  onAddApp,
}) => {
  const [formData, setFormData] = useState({
    app_name: '',
    project_number: '',
    description: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault();
      onAddApp(formData);
    } catch (error: any) {
      setErrorMessage(error.response.data.detail);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    setFormData({
      app_name: '',
      project_number: '',
      description: '',
    });
    validateData(formData.app_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleClose = () => {
    setErrorMessage(null);
    setShowErrorMessage(false);
    setFormData({
      app_name: '',
      project_number: '',
      description: '',
    });
    onClose();
  };

  const validateData = (name: string) => {
    if (name.length < 5) {
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'app_name') {
      validateData(value);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      {showErrorMessage ? (
        <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="py-8 font-sen-bold">Error: {errorMessage}</h1>
            <Button
              onClick={handleClose}
              label="Ok"
              size="normal"
              variant="primary"
              addClass="w-40"
            />
          </div>
        </div>
      ) : (
        <div className="bg-white p-8 rounded-lg w-1/2 min-w-fit border-2">
          <h1 className=" font-bold text-xl py-4 font-bandeins">Add App</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-black mb-2">App Name:</label>
              <input
                type="text"
                name="app_name"
                value={formData.app_name}
                onChange={handleChange}
                placeholder="The name of your app"
                className="p-2 w-full border rounded bg-gray-100 border-gray-300"
              />
              <div>
                {!isNameValid ? (
                  <p style={{ color: 'red' }}>
                    This Field is required. 5 chars or more.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-black mb-2">Projectnumber:</label>
              <input
                type="text"
                name="project_number"
                value={formData.project_number || ''}
                onChange={handleChange}
                placeholder="SAP projectnumber (optional)"
                className="p-2 w-full border rounded bg-gray-100 border-gray-300"
              />
            </div>

            <div className="mb-4">
              <label className="block text-black mb-2">Description:</label>
              <input
                type="text"
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
                placeholder="Descripe your app here (optional)"
                className="p-2 w-full border rounded bg-gray-100 border-gray-300"
              />
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div className="flex justify-around py-4">
              <Button
                disabled={!isNameValid}
                onClick={(e) => handleSubmit(e)}
                label="Add"
                size="normal"
                variant="primary"
                addClass="w-44"
                key={`${isNameValid}-button`} // TODO: refactor
              />
              <Button
                onClick={onClose}
                label="Close"
                size="normal"
                variant="neutral"
                addClass="w-44"
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
