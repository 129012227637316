import { ApiClient } from '@app/domain/platform/actions/types';

type MonthlyData = {
  [key: string]: number;
};

export type ModelUsageByMonthResponse = {
  [month: number]: MonthlyData;
};

export const getModelUsagesByMonth = ({ GET }: ApiClient) =>
  GET<ModelUsageByMonthResponse>('/api/admin/getModelUsagesByMonth').then(
    ({ data }) => data
  );
