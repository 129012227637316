export interface PictureProps {
  alt: string;
  src: string;
  mdSrc?: string;
  lgSrc?: string;
  className?: string;
}

export function Picture({ alt, src, mdSrc, lgSrc, className }: PictureProps) {
  return (
    <picture className={className}>
      {lgSrc && <source media="(min-width: 1024px)" srcSet={lgSrc} />}
      {mdSrc && <source media="(min-width: 768px)" srcSet={mdSrc} />}
      <img alt={alt} src={src} />
    </picture>
  );
}
