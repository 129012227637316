import { RootLayout } from "@app/domain/platform/templates/RootLayout";
import Router from "./Router";
import { Toaster } from "@/components/ui/toaster";

function App() {
  return (
    <RootLayout
      authentication={{
        authority:
          "https://login.microsoftonline.com/338159a0-dd8f-45af-9672-faaa54bc6b24",
        appScopes: [
          "api://ff505111-721e-484f-b857-c8443c934986/user_impersonation",
        ],
        clientId: "8cedcdfb-f3b7-40b9-8eca-2e7a3949d2e0",
        redirectUri: "/",
      }}
    >
      <Router />
      <Toaster />
    </RootLayout>
  );
}

export default App;
