import { ApiClient } from './types';

export type UserResponse = {
  isUser: boolean;
  isAdmin: boolean;
  isDev: boolean;
  name: string;
  id?: number;
  email?: string;
};

type UserResponseMap = UserResponse & { isUser: boolean };

export const getUser = ({ GET }: ApiClient) =>
  GET<UserResponse>('/api/user/getUser').then(
    ({ data }): UserResponseMap => ({
      ...data,
      isUser: true,
    })
  );
