import { useEffect, useState } from 'react';
import { useApiClient } from '@app/domain/platform/context/apiClient';
import { getModelUsagesByMonth } from '../actions/getModelUsagesByMonth';
import { ChartData } from './AdminContextProvider/AdminContext.types';

const useFetchModelUsageByM = () => {
    const [datasets, setDatasets] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(true);
    const apiClient = useApiClient();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getModelUsagesByMonth(apiClient);
                setDatasets(reformatData(data));
            } catch (error) {
                console.error('Error fetching data:', error);
                setDatasets([]); // Handle error by setting an empty array or any fallback data
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiClient]);

    const labels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const reformatData = (data: any): ChartData[] => {
        return Object.keys(data).map((monthKey) => {
            const monthIndex = parseInt(monthKey) - 1;
            const monthLabel = labels[monthIndex];
            return {
                month: monthLabel,
                models: data[parseInt(monthKey)],
            };
        });
    };

    return {
        datasets: loading ? [] : datasets,
    };
};

export default useFetchModelUsageByM;
