import { useCallback, useEffect, useState } from 'react';
import { getTools, Tool } from '../actions/getTools';
import { useApiClient } from '@app/domain/platform/context/apiClient';

const useTools = () => {
  const apiClient = useApiClient();
  const [toolInfo, setToolInfo] = useState<Tool[]>([]);
  const handleGetTools = useCallback(() => {
    getTools(apiClient).then(setToolInfo);
  }, [apiClient]);

  useEffect(() => {
    handleGetTools();
  }, [handleGetTools]);

  return { toolInfo, handleGetTools };
};

export default useTools;
