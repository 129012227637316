import {
  ButtonContext,
  ButtonSize,
  ButtonVariant,
  useButtonStyle,
} from '@ui/tokens/useButtonStyle';
import { LinkVariant, useLinkStyle } from '@ui/tokens/useLinkStyle';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

export type LinkProps = PropsWithChildren & {
  href: string;
  className?: string;
  target?: string;
} & (
    | {
        variant?: undefined;
        size?: ButtonSize;
        buttonVariant?: ButtonVariant;
        buttonContext?: ButtonContext;
      }
    | {
        variant?: LinkVariant;
        size?: undefined;
        buttonVariant?: undefined;
        buttonContext?: undefined;
      }
  );

export function Link({
  href,
  buttonVariant,
  buttonContext,
  children,
  size,
  variant,
  className,
  target,
}: LinkProps) {
  const buttonClasses = useButtonStyle({
    size,
    variant: buttonVariant,
    context: buttonContext,
  });

  const linkClasses = useLinkStyle({
    variant: buttonVariant ? undefined : variant || 'inline',
  });

  const linkProps = {
    className: classNames(className, buttonClasses, linkClasses),
    target,
    children,
  };

  if (href.startsWith('#')) {
    return <a {...linkProps} href={href} />;
  }

  return <RouterLink {...linkProps} to={href} />;
}
