import { useApiClient } from "@app/domain/platform/context/apiClient";
import { useCallback, useEffect, useState } from "react";
import { getAllUsersWithToken, User } from "../actions/getAllUsersWithToken";

export const useFetchUsers = () => {
  const apiClient = useApiClient();
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = useCallback(() => {
    getAllUsersWithToken(apiClient).then(setUsers).catch((error: Error) => {
      console.error("Failed to fetch users:", error);
    });
  }, [apiClient]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    users,
    fetchUsers,
  };
};