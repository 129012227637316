import { ApiClient } from '@app/domain/platform/actions/types';

//TODO: FIX TYPES

//TODO: FIX TYPES

export type ToolResponse = {
  id: number;
  title: string;
  link: string;
  description: string;
  image: string;
  contact: string;
  upvotes?: number;
  downvotes?: number;
  tags?: string[] | string;
  short_description?: string;
};

export type Tool = Omit<ToolResponse, 'short_description' | 'tags'> & {
  short_description?: string;
  long_description?: string;
  tags: string[];
};

export const getTools = ({ GET }: ApiClient) =>
  GET<ToolResponse[]>('api/v2/info/tools').then(({ data }) =>
    data.map(
      ({ short_description, tags = [], ...tool }) =>
        ({
          ...tool,
          shortDescription: short_description,
          tags: Array.isArray(tags) ? tags : [],
        }) as Tool
    )
  );
