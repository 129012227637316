
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"


 export type CardInfoProps = {
    title: string,
    value: string,
    subtitle: string,
    }


export function CardInfo({ title, value, subtitle }: CardInfoProps) {
  return (

          <Card x-chunk="dashboard-01-chunk-0">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {title}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{value}</div>
              <p className="text-xs text-muted-foreground">
               {subtitle}
              </p>
            </CardContent>
            </Card>
  )
}