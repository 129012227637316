import { ApiClient, Apps } from './types';

export type AppUsage = {
  name: string;
  total_tokens: number;
};

export type ModelUsage = {
  model_id: string;
  total_tokens: number;
};
export type UserDataResponse = {
  app_usage: AppUsage[];
  apps: Apps[];
  model_usage: ModelUsage[];
};

export const getUserData = ({ GET }: ApiClient) =>
  GET<UserDataResponse>('/api/user/getUserData').then(({ data }) => data);
