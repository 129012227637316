import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export interface TextProps extends PropsWithChildren {
  size?: 'm' | 's' | 'l';
  bold?: boolean;
  className?: string;
}

export function Text({ size = 'm', bold, children, className }: TextProps) {
  return (
    <span
      className={classNames(className, 'inline-block', {
        'font-sen': !bold,
        'font-sen-regular': bold,
        'text-16': size === 'm',
        'text-14': size === 's',
        'text-2xl': size === 'l',
      })}
    >
      {children}
    </span>
  );
}
