import 'swagger-ui-react/swagger-ui.css';
import SwaggerUI from 'swagger-ui-react';

export interface DocumentationViewerProps {
  specUrl: string;
}

export function DocumentationViewer({ specUrl }: DocumentationViewerProps) {
  return (
    <div className="w-full mx-auto pt-12">
      <SwaggerUI url={specUrl} />
    </div>
  );
}
