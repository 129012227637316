import React, { createContext, ReactNode, useMemo } from 'react';
import { AdminContextType } from './AdminContext.types';
import { useFetchUsers } from '../useFetchUsers';
import { useFetchApps } from '../useFetchApps';
import { useFetchUseCases } from '../useFetchUseCases';
import useTotalApps from '../useTotalApps';
import useTotalTokens from '../useTotalTokens';
import useTotalUsers from '../useTotalUsers';
import useFetchModelUsageByM from '../useFetchModelUsageByMonth';

export const AdminContext = createContext<AdminContextType | undefined>(undefined);

interface AdminProviderProps {
    children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
    const { users, fetchUsers } = useFetchUsers();
    const { apps, fetchApps } = useFetchApps();
    const { useCases, fetchUseCases } = useFetchUseCases();

    const { datasets } = useFetchModelUsageByM();
    const totalApps = useTotalApps().totalApps;
    const totalTokens = useTotalTokens().totalTokens;
    const activeUsers = useTotalUsers().activeUsers;
    const knownUsers = useTotalUsers().knownUsers;

    const totalStatistics = useMemo(
        () => ({
            totalApps,
            totalTokens,
            activeUsers,
            knownUsers,
        }),
        [totalApps, totalTokens, activeUsers, knownUsers],
    );

    const contextValue: AdminContextType = {
        users: users.map((user) => ({
            ...user,
            user_role: String(user.user_role), // Convert user_role to string if necessary
        })),
        apps,
        useCases,
        totalStatistics,
        refreshUsers: fetchUsers,
        refreshApps: fetchApps,
        refreshUseCases: fetchUseCases,
        datasets,
    };

    return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};
