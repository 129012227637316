import React from "react";
import { AppsTableColumns } from "./AppsTableColumns";
import { DataTable } from "@/ui/modules/DataTable/DataTable";
import { AppsTableProps } from "./AppsTable.type";


export const AppsTable: React.FC<AppsTableProps> = ({ data }) => {
    return (
        <div>
            <DataTable columns={AppsTableColumns} data={data} />
        </div>
    )
};