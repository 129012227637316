import axios, { AxiosError, AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: '/',
});

export enum HttpError {
  E401 = 401,
  E403 = 403,
}

/**
 * @deprecated use useApiClient instead
 */
export const makeApiCall = async <T, R>(
  getAuthorization: () => Promise<string>,
  getAuthorizationWithInteraction: () => Promise<string>,
  url: string,
  method = 'GET',
  data?: T,
  retry = true
): Promise<AxiosResponse<R>> => {
  /**
   * patch EXXAIPS-115
   * tricky because it is not allowed to use async getAuthorization and getAuthorizationWithInteraction direct inside try..catch block.
   * this will use the same loop to get a token like these methods and then it loops try..catch block :(
   * retry only to get token with interaction
   */

  const authToken = await getToken(
    getAuthorization,
    getAuthorizationWithInteraction,
    retry
  );

  try {
    return await api({
      method,
      url,
      headers: {
        Authorization: authToken,
      },
      data,
    });
  } catch (error) {
    const { request } = error as AxiosError;
    const httpStatus = request.status;

    if (
      retry &&
      (httpStatus === HttpError.E401 || httpStatus === HttpError.E403)
    ) {
      return makeApiCall(
        getAuthorization,
        getAuthorizationWithInteraction,
        url,
        method,
        data,
        false
      );
    } else {
      throw new Error(`API call failed silently`);
    }
  }
};

async function getToken(
  getAuthorization: () => Promise<string>,
  getAuthorizationWithInteraction: () => Promise<string>,
  retry: boolean
): Promise<string> {
  return new Promise((resolve, reject) => {
    getAuthorization()
      .then((token) => {
        return resolve(token);
      })
      .catch(() => {
        if (retry) {
          getAuthorizationWithInteraction()
            .then((token2) => {
              return resolve(token2);
            })
            .catch(() => {
              throw new Error(`API call failed after popup`);
            });
        } else {
          return reject();
        }
      });
  });
}
