import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { Link } from '@ui/components/Link';

export interface DevToolCardProps {
  item: {
    description: string;
    title: string;
    link: string;
  };
}

export const DevToolCard = ({ item }: DevToolCardProps) => {
  const titleContainerStyle: React.CSSProperties = {
    width: '100%',
    wordWrap: 'break-word',
  };
  return (
    <div>
      <div className="tips-item-layout py-5">
        <div className="my-auto flex justify-between">
          <div style={titleContainerStyle}>
            <div className="pb-3 h-tips-title-layout text-2xl md:text-3xl font-bandeins">
              <div>{item.title}</div>
            </div>
            <div className="text-gray-500 md:text-lg font-sen">
              <p className="tips-description">{item.description}</p>
            </div>
          </div>
          <div className="v-open-tip center-items">
            <Link href={item.link} target="_blank">
              <LinkIcon style={{ fontSize: '36px' }} />
            </Link>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};
