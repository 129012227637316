export interface ThemeConfig {
  primaryColor: string;
  secondaryColor: string;
  heroImage: string;
  companyLogo: string;
  companyName: string;
  primaryFont?: string;
  secondaryFont?: string;
  headingFont?: string;
}

export const themeConfig: ThemeConfig = {
  primaryColor: '#000000',
  secondaryColor: '#FFFFFF',
  heroImage: 'hero.jpg',
  companyLogo: 'logo.jpg',
  companyName: 'Company Name',
};
