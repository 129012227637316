import { PropsWithChildren } from 'react';
import { useUserContext } from '../context/UserContext';
import HundehaufenImage from '@assets/exxeta_Hundehaufen_positiv.png';

interface ProtectedPageProps extends PropsWithChildren {
  requireIsAdmin?: boolean;
  requireIsDev?: boolean;
}

export function ProtectedPage({
  children,
  requireIsAdmin,
  requireIsDev,
}: ProtectedPageProps) {
  const {
    userData: { isInitialized, isAdmin, isDev },
  } = useUserContext();

  const isAuthorized = () => {
    if (!isInitialized) {
      return false;
    }

    if (requireIsAdmin && !isAdmin && requireIsDev && !isDev) {
      return false;
    }

    return true;
  };

  return isAuthorized() ? (
    children
  ) : (
    <div className="w-full mt-36 text-sm ">
      <div className="container mx-auto">
        <h1 className="mt-4">[403] - Sorry, no Admin rights.</h1>
        <img
          className="w-1/2 mx-auto"
          src={HundehaufenImage}
          alt="No Admin rights"
        />
      </div>
    </div>
  );
}
