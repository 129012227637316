import { ApiClient } from '@app/domain/platform/actions/types';
import { useCallback, useEffect, useState } from 'react';
import { App } from '../context/AdminContextProvider/AdminContext.types';
import { useApiClient } from '@app/domain/platform/context/apiClient';

export const fetchApps = ({ GET }: ApiClient) =>
    GET<App[]>('/api/admin/apps').then((response) => {
        return response.data;
    });

export const useFetchApps = () => {
    const apiClient = useApiClient();
    const [apps, setApps] = useState<App[]>([]);

    const fetchAppsData = useCallback(() => {
        fetchApps(apiClient)
            .then(setApps)
            .catch((error: Error) => {
                console.error('Failed to fetch users:', error);
            });
    }, [apiClient]);

    useEffect(() => {
        fetchAppsData();
    }, [fetchAppsData]);

    return {
        apps,
        fetchApps: fetchAppsData,
    };
};
