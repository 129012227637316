import { HeadingProps, Heading } from '@ui/components/Heading';
import { Link, LinkProps } from '@ui/components/Link';
import { SectionFrame } from '@ui/components/SectionFrame';
import { Text, TextProps } from '@ui/components/Text';

export interface FooterProps {
  heading: Partial<HeadingProps>;
  link: LinkProps;
  text: TextProps;
  copyrightText: TextProps;
  version: TextProps;
}

export const Footer = ({
  link,
  text,
  copyrightText,
  heading,
  version,
}: FooterProps) => {
  return (
    <SectionFrame isDarkBackground={true} className="text-white">
      <div className="flex flex-col items-center text-center bg-black py-12">
        <Heading
          variant="h3"
          className="font-bandein-ha font-bold leading-none mb-4 text-4xl xl:text-5xl"
          {...heading}
        />
        <Text {...text} size={'l'} />
        <Link
          className="mt-8"
          {...link}
          variant={undefined}
          buttonContext="dark"
          buttonVariant="primary"
          target="_blank"
        />
        <div className="mt-9">
          <Text {...copyrightText} size="s" />
        </div>
      </div>
      <div className="text-right">
        <Text {...version} size="s" />
      </div>
    </SectionFrame>
  );
};
