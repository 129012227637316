import React, { PropsWithChildren, useMemo } from 'react';

export interface UseChildrenOfType extends PropsWithChildren {
  type: unknown;
}

export function useChildrenOfType({ children, type }: UseChildrenOfType) {
  return useMemo(
    () =>
      React.Children.toArray(children).filter(
        ({ type: childType }: any) => type === childType
      ),
    [children, type]
  );
}
