import './styles.css';
import { Text, TextProps } from '@ui/components/Text';

interface HomeTextProps {
  text: TextProps;
}

// TODO rename
const HomeText = ({ text }: HomeTextProps) => {
  return (
    <div className="my-16 lg:my-32 3xl:my-44 2xl:max-w-screen-2xl 2xl:mx-auto px-6 sm:px-0 2xl:px-10 container mx-auto">
      <Text {...text} size="l" className="text-center"></Text>
    </div>
  );
};

export default HomeText;
