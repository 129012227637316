import { HomePage } from '@app/domain/information/pages/HomePage';
import { UseCases } from '@app/domain/information/pages/UseCases';
import { DocumentationPage } from '@app/domain/information/pages/DocumentationPage';

export const informationRoutes = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/use-cases',
    element: <UseCases />,
  },
  {
    path: '/api-reference',
    element: <DocumentationPage />,
  },
];
