import { DevToolCard } from '@ui/modules/DevToolCard';
import { Heading, HeadingProps } from '@ui/components/Heading';
import { Text, TextProps } from '@ui/components/Text';
import { SectionFrame } from '@ui/components/SectionFrame';

interface DevToolCardProps {
  heading: Partial<HeadingProps>;
  text: TextProps;
  publicUrls: PublicUrl[];
}

interface PublicUrl {
  title: string;
  description: string;
  link: string;
}

const DevTools = ({ heading, text, publicUrls }: DevToolCardProps) => {
  return (
    <div className={'bg-[#F2F2F2]'}>
      <SectionFrame>
        <Heading variant="h1" {...heading} />
        <Text size="l" {...text} />
        {publicUrls.map((tip, index) => {
          return <DevToolCard key={index} item={tip} />;
        })}
      </SectionFrame>
    </div>
  );
};

export default DevTools;
