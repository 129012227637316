import { HttpStatusCode } from 'axios';
import { ApiClient, Apps } from './types';

export type AppCreateResponse = {
  status: HttpStatusCode;
};

export type AppCreateRequest = Apps;

export const createApp = (
  { POST }: ApiClient,
  appPostRequestData: AppCreateRequest
) =>
  POST<AppCreateRequest, AppCreateResponse>(
    `/api/user/createApp`,
    appPostRequestData
  ).then(({ status }) => status);
