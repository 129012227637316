import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo } from 'react';

export interface UseAuthenticationContextBehaviorProps {
  appScopes: string[];
}

const createTokenOfAuthResult = ({ accessToken }: AuthenticationResult) =>
  `Bearer ${accessToken}`;

export const useAuthenticationContextBehavior = ({
  appScopes,
}: UseAuthenticationContextBehaviorProps) => {
  const { inProgress, instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const request = useMemo(
    () => ({ scopes: appScopes, account: accounts[0] }),
    [accounts, appScopes]
  );

  const getAuthorization = useCallback(
    () => instance.acquireTokenSilent(request).then(createTokenOfAuthResult),
    [instance, request]
  );

  const getAuthorizationWithInteraction = useCallback(
    () => instance.acquireTokenPopup(request).then(createTokenOfAuthResult),
    [instance, request]
  );

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect({ scopes: appScopes });
    }
  }, [appScopes, inProgress, instance, isAuthenticated]);

  return useMemo(
    () => ({
      inProgress,
      accounts,
      getAuthorization,
      getAuthorizationWithInteraction,
    }),
    [accounts, getAuthorization, getAuthorizationWithInteraction, inProgress]
  );
};
