import { Dispatch, SetStateAction } from 'react';
import { FiCopy } from 'react-icons/fi';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Apps } from '../actions/types';

type appTableProps = {
  appList: Apps[];
  setCopyPopUp: Dispatch<SetStateAction<boolean>>;
  edit: (item: Apps) => void;
  delete: (id: number) => void;
  //setCopyPopUp: (value: boolean) => boolean;
};
const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

const copyToClipboard = (
  text: string,
  setCopyPopUp: Dispatch<SetStateAction<boolean>>
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      setCopyPopUp(true);
      setTimeout(() => setCopyPopUp(false), 1500);
    })
    .catch((err) => {
      console.error('Could not copy text: ', err);
    });
};
// TODO refactor - move reusable markup to ui components
export const AppTable = (props: appTableProps) => {
  return (
    <>
      {props.appList.map((item, index) => {
        return (
          <tr
            key={index}
            className="border-b border-gray-200 hover:bg-gray-100 h-14"
          >
            <td className="py-1  px-2 md:px-4 text-start">{item.id}</td>
            <td className="py-1  px-2 md:px-4 text-start font-bold">
              {item.name}
            </td>
            <td className="py-1  px-2 md:px-4 text-start">
              {item.description}
            </td>
            <td className="py-1  px-2 md:px-4 text-center">
              {item.project_number}
            </td>
            <td className="py-1  px-2 md:px-4 text-start">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  className="overflow-hidden text-ellipsis cursor-pointer"
                  title="Click to copy"
                >
                  {truncate(item.access_token, 10)}
                </span>
                <button
                  style={{ marginLeft: '10px' }}
                  onClick={() =>
                    copyToClipboard(item.access_token, props.setCopyPopUp)
                  }
                  title="Click to copy"
                >
                  <FiCopy />
                </button>
              </div>
            </td>
            <td className="py-1  px-2 md:px-4 flex justify-center h-14">
              <IconButton
                aria-label="edit"
                size="small"
                onClick={() => props.edit(item)}
              >
                <EditIcon />
              </IconButton>

              {/* Delete action */}
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => props.delete(item.id)}
                className="ml-2"
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        );
      })}
    </>
  );
};
