import classNames from 'classnames';
export type LinkVariant = 'inline';

export interface UseLinkStyleProps {
  variant?: LinkVariant;
}

export function useLinkStyle({ variant }: UseLinkStyleProps) {
  return classNames({
    'font-sen-regular underline': variant === 'inline',
  });
}
