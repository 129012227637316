import { useEffect, useState } from 'react';

import { useApiClient } from '@app/domain/platform/context/apiClient';
import { getTotalTokens, TotalTokensMap } from '../actions/getTotalTokens';

const useTotalTokens = () => {
  const [totalTokens, setTotalTokens] = useState<TotalTokensMap>({
    totalTokens: 0,
  });
  const apiClient = useApiClient();

  useEffect(() => {
    getTotalTokens(apiClient).then(setTotalTokens);
  }, [apiClient]);

  return totalTokens;
};

export default useTotalTokens;
