export type userModelUsage = {
  total_tokens: number;
  model_id: string | null; // Assuming model_id can be null based on your check
};

type userModelUsageTableProps = {
  appList: userModelUsage[];
};

// TODO refactor - move reusable markup to ui components
export const UsageTable = ({ appList }: userModelUsageTableProps) => {
  // Filter out entries with null model_id before mapping
  const validApps = appList.filter((app) => app.model_id !== null);

  return (
    <>
      <table className="min-w-full border rounded-md">
        <thead>
          <tr className="text-black uppercase text-sm leading-normal font-sen-bold border-b-2 border-gray-200">
            <th
              className="py-2 px-2 md:px-4 text-start"
              style={{ width: '60%' }}
            >
              Model ID
            </th>
            <th
              className="py-2 px-2 md:px-4 text-start"
              style={{ width: '40%' }}
            >
              Usage Tokens
            </th>
          </tr>
        </thead>
        <tbody className="text-black text-sm font-light">
          {validApps.length > 0 ? (
            validApps.map((item, index) => (
              <tr
                key={index}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td
                  className="py-2 px-2 md:px-4 text-start"
                  style={{ width: '60%' }}
                >
                  {item.model_id}
                </td>
                <td
                  className="py-2 px-2 md:px-4 text-start"
                  style={{ width: '40%' }}
                >
                  {item.total_tokens.toLocaleString('de-DE').replace(/,/g, '.')}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2} className="text-center py-2">
                No usage data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
