import { Accordion, AccordionProps } from '@ui/modules/Accordion';
import { Heading, HeadingProps } from '@ui/components/Heading';
import { SectionFrame } from '@ui/components/SectionFrame';

interface FAQProps {
  heading: Partial<HeadingProps>;
  questions: AccordionProps[];
}

export const FAQ = ({ heading, questions }: FAQProps) => {
  return (
    <SectionFrame>
      <Heading variant="h1" {...heading} />
      <div>
        {questions.map((question, index) => (
          <Accordion key={index} {...question} />
        ))}
      </div>
    </SectionFrame>
  );
};
