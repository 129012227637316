import { ApiClient } from '@app/domain/platform/actions/types';

export type TotalAppsResponse = { total_apps: number };
export type TotalAppsMap = { totalApps: number };

export const getTotalApps = ({ GET }: ApiClient) =>
  GET<TotalAppsResponse>('/api/admin/getTotalApps').then(
    ({ data }): TotalAppsMap => ({
      totalApps: data.total_apps,
    })
  );
