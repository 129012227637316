import { PropsWithChildren, createContext } from 'react';
import { useUserContextBehavior } from './useUserContextBehavior';

export type UserContextState = ReturnType<typeof useUserContextBehavior>;

export type App = {
  access_token?: string;
  name: string;
  description: string;
  email: string;
  id: number;
  project_number: string;
  user_id?: string;
};

export type Tool = {
  id: number;
  title: string;
  link: string;
  description: string;
  image: string;
  contact: string;
};

export const UserContext = createContext<UserContextState | undefined>(
  undefined
);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const userData = useUserContextBehavior();

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};
