import {
    type ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent,
} from '@/components/ui/chart';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import { Card, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { ChartData } from '../../context/AdminContextProvider/AdminContext.types';

export interface LineChartProps {
    datasets: ChartData[];
}

const chartConfig = {
    gpt4o: {
        label: 'GPT-4o',
        color: 'hsl(var(--chart-1))',
    },
    gpt35turbo: {
        label: 'GPT-3.5-Turbo',
        color: 'hsl(var(--chart-2))',
    },
    gpt4turbo: {
        label: 'GPT-4-Turbo',
        color: 'hsl(var(--chart-4))',
    },
    gpt35turbo16k: {
        label: 'GPT-3.5-Turbo-16k',
        color: 'hsl(var(--chart-5))',
    },
    gpt4omini: {
        label: 'GPT-4o-Mini',
        color: 'hsl(var(--chart-6))',
    },
} satisfies ChartConfig;

export function LineChart({ datasets }: LineChartProps) {
    const transformData = (data: ChartData[]): Record<string, any>[] => {
        return data.map((entry) => {
            const formattedEntry: Record<string, any> = { month: entry.month };
            for (const [modelId, tokens] of Object.entries(entry.models)) {
                formattedEntry[modelId.replace(/-/g, '')] = tokens;
            }
            return formattedEntry;
        });
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Consumption by Model</CardTitle>
                <CardDescription>2024</CardDescription>
            </CardHeader>
            <ChartContainer config={chartConfig} className="min-h-[400px] w-full">
                <BarChart accessibilityLayer data={transformData(datasets)}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="month"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar dataKey="gpt4o" fill="var(--color-gpt4o)" radius={4} />
                    <Bar dataKey="gpt35turbo" fill="var(--color-gpt35turbo)" radius={4} />
                    <Bar dataKey="gpt4turbo" fill="var(--color-gpt4turbo)" radius={4} />
                    <Bar dataKey="gpt35turbo16k" fill="var(--color-gpt35turbo16k)" radius={4} />
                    <Bar dataKey="gpt4omini" fill="var(--color-gpt4omini)" radius={4} />
                </BarChart>
            </ChartContainer>
        </Card>
    );
}
