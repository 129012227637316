import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
} from 'chart.js';
import { UserContextProvider } from '@app/domain/platform/context/UserContext';
import { ThemeContextProvider } from '@app/domain/platform/context/ThemeContext';
import { AuthenticationContextProvider } from '../context/AuthenticationContext';
import { AuthenticationContextProviderProps } from '../context/AuthenticationContext/AuthenticationContext';
import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Navbar } from './Navbar';

export interface RootLayoutProps extends PropsWithChildren {
  authentication: AuthenticationContextProviderProps;
}

export function RootLayout({ authentication, children }: RootLayoutProps) {
  // TODO move this into chart components
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
  );

  return (
    <AuthenticationContextProvider {...authentication}>
      <BrowserRouter>
        <UserContextProvider>
          <ThemeContextProvider>
            {/* TODO refactor - move reusable markup to ui components  */}
            <div className="relative">
              <Navbar />
              <div className="mx-auto main-page h-auto v-auto-fr gap">
                {children}
              </div>
            </div>
          </ThemeContextProvider>
        </UserContextProvider>
      </BrowserRouter>
    </AuthenticationContextProvider>
  );
}
