import { ApiClient } from '@app/domain/platform/actions/types';

export type TotalTokensResponse = { total_tokens: number };
export type TotalTokensMap = { totalTokens: number };

export const getTotalTokens = ({ GET }: ApiClient) =>
  GET<TotalTokensResponse>('/api/admin/getTotalTokens').then(
    ({ data }): TotalTokensMap => ({
      totalTokens: data.total_tokens,
    })
  );
