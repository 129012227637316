import { Text, TextProps } from '@ui/components/Text';
import React from 'react';
import { Heading, HeadingProps } from '@ui/components/Heading';
import { Picture, PictureProps } from '@ui/components/Picture';
import { SectionFrame } from '@ui/components/SectionFrame';

interface VarietyProps {
  heading: Partial<HeadingProps>;
  text: TextProps;
  logos: PictureProps[];
}

const VarietyComponent = ({ heading, text, logos }: VarietyProps) => {
  const titleContainerStyle: React.CSSProperties = {
    width: '100%',
    wordWrap: 'break-word',
  };
  const logosContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  };

  return (
    <SectionFrame isDarkBackground={true}>
      <div
        className="text-center bg-black text-white py-12"
        style={titleContainerStyle}
      >
        <Heading {...heading} variant="h3" className="text-5xl pt-5"></Heading>
        <Text {...text} size="l"></Text>
        <br />
        <div className="pt-6" style={logosContainerStyle}>
          {logos.map((logo, index) => (
            <Picture className="ml-5 h-18 w-20" key={index} {...logo} />
          ))}
        </div>
      </div>
    </SectionFrame>
  );
};

export default VarietyComponent;
