import { useApiClient } from '@app/domain/platform/context/apiClient';
import { useEffect, useState } from 'react';
import { getTotalUsers, TotalUsersMap } from '../actions/getTotalUsers';

const useTotalUsers = () => {
  const [totalUsers, setTotalUsers] = useState<TotalUsersMap>({
    knownUsers: 0,
    activeUsers: 0,
  });
  const apiClient = useApiClient();

  useEffect(() => {
    getTotalUsers(apiClient).then(setTotalUsers);
  }, [apiClient]);

  return totalUsers;
};

export default useTotalUsers;
