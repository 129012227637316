import { Heading, HeadingProps } from '@ui/components/Heading';
import { Picture, PictureProps } from '@ui/components/Picture';
import { useChildrenOfType } from '@ui/tokens/useChildrenOfType';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

function BackgroundImage(props: PictureProps) {
  return <Picture {...props} />;
}

function Title(props: Partial<HeadingProps>) {
  return <Heading variant="h4" {...props} />;
}
function Subtitle(props: Partial<HeadingProps>) {
  return <Heading variant="h6" {...props} />;
}
function Content({ children }: PropsWithChildren) {
  return children;
}
function Footer({ children }: PropsWithChildren) {
  return <div className="pt-2 flex justify-end">{children}</div>;
}

export interface CardProps extends PropsWithChildren {
  className?: string;
}

function Card({ children, className }: CardProps) {
  const backgroundImage = useChildrenOfType({
    children,
    type: BackgroundImage,
  });

  return (
    <div
      className={classNames(
        className,
        'bg-white rounded-2xl shadow-lg flex flex-col overflow-hidden relative'
      )}
    >
      {!!backgroundImage.length && (
        <div className="relative rounded-t-2xl -mb-3">{backgroundImage}</div>
      )}

      <div className="p-4 rounded-2xl relative z-10 bg-white flex flex-col h-full">
        <div>
          {useChildrenOfType({ children, type: Title })}
          {useChildrenOfType({ children, type: Subtitle })}
        </div>
        <div className="flex-grow">
          {useChildrenOfType({ children, type: Content })}
        </div>
        <div>{useChildrenOfType({ children, type: Footer })}</div>
      </div>
    </div>
  );
}

Card.Title = Title;
Card.Content = Content;
Card.Footer = Footer;
Card.BackgroundImage = BackgroundImage;
Card.Subtitle = Subtitle;

export default Card;
