import { ApiClient } from '@app/domain/platform/actions/types.ts';
import { Simulate } from 'react-dom/test-utils';
import error = Simulate.error;

export interface MontlyUsageEntry {
    model_id: string;
    total_tokens_used: number;
    total_output_tokens: number;
    total_input_tokens: number;
    total_output_costs: number;
    total_input_costs: number;
}

interface MontlyUsages {
    [month: string]: MontlyUsageEntry[];
}
export interface AppMontlyUsage {
    app_id: number;
    app_name: string;
    user_id: number;
    monthly_usages: MontlyUsages;
}
export const getUsersExportCsv = ({ GET }: ApiClient, appId: number) =>
    GET<AppMontlyUsage>(`/api/admin/apps/${appId}/token-consumption`).then((response) => {
        return response.data;
    });

export const handleExportData = async (appId: number, apiClient: ApiClient) => {
    let data: AppMontlyUsage | null = null;
    try {
        data = await getUsersExportCsv(apiClient, appId);
    } catch (error) {
        console.error('Faild to fetch data:', error);
        return;
    }

    if (!data) {
        console.error('No data available for export:', error);
        return;
    }

    // Convert the mock data to CSV format
    const csvHeaders = ['app_id', 'app_name', 'user_id', 'month', 'model_id', 'total_tokens', 'total_costs'];
    const csvRows: any[] = [];

    // Loop through the monthly usages to create rows for each entry
    for (const [month, usages] of Object.entries(data.monthly_usages)) {
        usages.forEach((usage: any) => {
            const row = [
                data.app_id,
                data.app_name,
                data.user_id,
                month,
                usage.model_id,
                usage.total_output_tokens + usage.total_input_tokens,
                usage.total_output_costs + usage.total_input_costs !== null
                    ? (usage.total_output_costs + usage.total_input_costs).toFixed(2)
                    : '', // Format cost with 2 decimal places
            ];
            csvRows.push(row.join(','));
        });
    }

    const csvContent = [csvHeaders.join(','), ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const now = new Date();
    const timestamp = now.toISOString().replace(/[-:]/g, '').split('.')[0];
    const filename = `AppExport_${data.app_id}_${timestamp}.csv`;

    // Set the download attribute with the filename
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', filename);

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    if (document.body.contains(link)) {
        document.body.removeChild(link);
    }
};
