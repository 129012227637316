import { useCallback } from 'react';
import { useApiClient } from './useApiClient';
import { ApiClient } from '@app/domain/platform/actions/types';

type OmitFirstArg<F> = F extends (x: ApiClient, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export function withApiClient<
  CallbackType extends CallableFunction,
  ParameterTypes,
>(callback: CallbackType) {
  return function useWithApiClient() {
    const apiClient = useApiClient();

    return useCallback(
      (...args: ParameterTypes[]) => callback(apiClient, ...args),
      [apiClient]
    ) as OmitFirstArg<CallbackType>;
  };
}
