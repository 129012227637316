import HomeHero from '@ui/patterns/HomeHero';
import HomeText from '@ui/patterns/HomeText';
import VarietyComponent from '@ui/patterns/Variety';
import DevTools from '@ui/modules/DevTools';
import { FAQ } from '@ui/modules/Faq';
import { Footer } from '@app/domain/platform/templates/Footer.tsx';
import { ComingSoon } from '@ui/patterns/ComingSoon';
import { Tools } from '@app/domain/information/templates/Tools';
import { Link } from '@ui/components/Link';
import cloudImage from '@assets/cloud.jpg';
import heroImage from '@assets/group_33.png';
import ExxetaBrain from '@assets/exxeta_brain-circuit_white.png';
import ExxetaEnter from '@assets/exxeta_Enter_positiv-01.png';
import ExxetaGrowingTree from '@assets/exxeta_GrowingBig_positiv.png';
import ExxetaCreative from '@assets/exxeta_KreativeHaare_negativ.png';
import azureLogo from '@assets/azure_logo.svg';
import openaiLogo from '@assets/openai_logo.svg';
import awsLogo from '@assets/aws_logo.svg';
import claudeAiLogo from '@assets/claudeai_logo.png';
import { InformationText } from '@ui/patterns/InformationText/InformationText';

export const HomePage: React.FC = () => {
  return (
    <>
      <HomeHero
        heading={{ children: 'ai.exxeta' }}
        text={{
          children: 'Accelerate Transformation Through AI-Driven Innovation',
        }}
        casesLink={{ href: '#toolView', children: 'cases' }}
        docsLink={{ href: '/api-reference', children: 'docs' }}
        backgroundImage={{ src: cloudImage }}
        heroImage={{ src: heroImage, alt: 'Hero Image' }}
      />
      <HomeText
        text={{
          children:
            'Becoming an AI-driven company - here you will find different GenAI- and Machine-Learning-Models to turn your ideas into reality! These AI tools provide a protected environment so that all data remains inside the company.',
        }}
      />
      <VarietyComponent
        text={{
          children:
            'The Exxeta AI Platform offers a variety of models from different providers.',
        }}
        heading={{ children: 'Variety' }}
        logos={[
          {
            src: azureLogo,
            alt: 'Azure Logo',
          },
          {
            src: openaiLogo,
            alt: 'Open AI Logo',
          },
          {
            src: awsLogo,
            alt: 'AWS Logo',
          },
          {
            src: claudeAiLogo,
            alt: 'Cloud AI Logo',
          },
        ]}
      />
      <InformationText
        text="Imagine a world where AI is as accessible as the internet. That's the world we're creating at ai.exxeta.com."
        answer="Here, innovation isn't just a buzzword; it's the heartbeat of over a thousand engineers creating the best products for tomorrow. With our platform, harnessing the power of AI is as simple as a click. We've democratized generative AI, making it programmatically accessible, unbound by bureaucracy, and tailored to your unique engineering needs."
        image={ExxetaEnter}
        isDarkBackground={false}
      />
      <InformationText
        text="Craft Excellence with Generative AI"
        answer="At Exxeta AI, we believe the best products are born from the freedom to explore. That's why we've built a space where generative AI isn't just an add-on—it's a core ingredient. Our platform is the canvas, and your vision is the masterpiece. Together, let's set new benchmarks for what technology can achieve."
        image={ExxetaCreative}
        isDarkBackground={true}
      />
      <InformationText
        text="Empowerment Through Innovation"
        answer="Step into a realm where the only limit is your imagination. Whether it's refining existing software or pioneering new solutions, Exxeta AI provides the tools to infuse generative AI into your products effortlessly. It's time to transcend the ordinary and create the extraordinary. Join the AI Revolution Embrace the mission to not just keep pace but to set it. With Exxeta AI, you're not just building software; you're crafting the future. Let's bring the best products to life, with generative AI as your co-creator."
        image={ExxetaGrowingTree}
        isDarkBackground={false}
      />
      <InformationText
        text="Welcome to ai.exxeta.com."
        answer="Innovation awaits."
        image={ExxetaBrain}
        isDarkBackground={true}
      />
      <Tools />
      <ComingSoon
        coming_soon={[
          {
            span: [
              {
                text: 'jABRE Bot',
              },
              {
                text: 'Legal Automation',
                dark: true,
              },
              {
                text: 'IT Modernization',
              },
              {
                text: 'ExxetaGPT',
                dark: true,
              },
              {
                text: 'Engineering Productivity',
              },
              {
                text: 'Customer Service',
              },
            ],
          },
          {
            span: [
              {
                text: 'ExxetaGPT',
              },
              {
                text: 'jABRE Bot',
                dark: true,
              },
              {
                text: 'Customer Service',
              },
              {
                text: 'IT Modernization',
                dark: true,
              },
              {
                text: 'Legal Automation',
              },
              {
                text: 'Engineering Productivity',
              },
            ],
          },
          {
            span: [
              {
                text: 'Legal Automation',
              },
              {
                text: 'IT Modernization',
              },
              {
                text: 'Engineering Productivity',
                dark: true,
              },
              {
                text: 'jABRE Bot',
              },
              {
                text: 'Customer Service',
                dark: true,
              },
              {
                text: 'ExxetaGPT',
              },
            ],
          },
        ]}
      />
      <DevTools
        heading={{ children: 'Prompting' }}
        publicUrls={[
          {
            title: 'How to write better ChatGPT Prompts',
            description:
              'Mastering chatbot prompts for optimal AI results: Upgrade your prompt-writing skills.',
            link: 'https://www.zdnet.com/article/how-to-write-better-chatgpt-prompts/',
          },
          {
            title: 'Awesome GPT Prompts',
            description:
              'Explore a wide range of prompts here, and feel free to contribute your own or generate new prompts using ChatGPT.',
            link: 'https://github.com/f/awesome-chatgpt-prompts',
          },
          {
            title: 'OpenAI Cookbook',
            description: 'Examples and guides for using the OpenAI API',
            link: 'https://github.com/openai/openai-cookbook/tree/main/examples',
          },
        ]}
        text={{ children: 'By Developers, for Developers' }}
      />
      <FAQ
        heading={{ children: 'FAQ' }}
        questions={[
          {
            title: { children: 'Is my data being used to re-train models?' },
            children: 'no.',
          },
          {
            title: {
              children:
                'Can I use the exxeta AI platform with internally classified data?',
            },
            children: 'yes.',
          },
          {
            title: {
              children: 'Can I use the exxeta AI platform with customer data?',
            },
            children: (
              <>
                In general yes, since exxeta AI platform is a local, isolated
                platform without data using for re-training. However, the
                contract with the customer is authoritative. Besides,{' '}
                <Link href="https://confluence.exxeta.network/display/UHEX/Policy_EXX_KI_Policy">
                  the Exxeta AI Policy applies.
                </Link>
              </>
            ),
          },
          {
            title: {
              children:
                'Will there be a usage limit, and if so, what will it look like?',
            },
            children: 'yes. we do not know yet.',
          },
          {
            title: {
              children:
                'Can I use the exxeta AI platform for my production system?',
            },
            children:
              'no. the platform is meant to be a sandbox for prototyping ideas and showcasing cases to customers. Depending on how things are going, we might consider production support in a later phase.',
          },
          {
            title: {
              children:
                'I have an interesting idea but I’m not sure if it’d be a good AI use case - can I discuss my idea with you?',
            },
            children: 'certainly - just let us know.',
          },
        ]}
      />
      <Footer />
    </>
  );
};
